import "./App.css";
import React from "react";
// eslint-disable-next-line no-unused-vars
import { Routes, Route, Navigate } from "react-router-dom";

import Login from "./pages/Auth/login";
import Layout from "./components/Layout/Layout"
import DealerRequest from "./pages/DealerRequest"
import VehicleRequest from "./pages/VehicleRequest"
import UpdateRequest from "./pages/UpdateRequest";
import Home from "./website/Home";
import About from "./website/About";
import Contact from "./website/Contact";
// import Test from "./pages/Auth/Test";
// import TestMessage from "./website/TestMessage";

const Router = () => {
  return (
    <Routes>
      {/* <Route
        path="/"
        element={<Navigate to="/login" element={<Home />} />}
      /> */}
      
      <Route path="/" element={<Home />} />
      <Route path="/Home" element={<Home />} />
      <Route path="/About" element={<About />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/login" element={<Login />} />
      <Route path="/layout/*" element={<Layout />} />
      <Route path="/dealer-requests" element={<DealerRequest />} />
      <Route path="/vehicle-requests" element={<VehicleRequest />} />
      <Route path="/update-requests" element={<UpdateRequest />} />
      <Route path="/admin" element={<Login />} />
    </Routes>
  );
};

export default Router;



// import "./App.css";
// import Layout from "./components/Layout/Layout";

// function App() {
//   return <Layout />;
// }

// export default App;