/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Sidebar from "../components/Sidebar/Sidebarr";
import TopNav from "../components/TopNav/TopNav";
import { BASE_URL } from '../api/config';
import "../styles/updaterequest.css";
import ConfirmationModal from "../components/Model/ConfirmationModal"; // Import your modal component
import ImagePopup from '../components/reuseable/ImagePopup';
import Swal from 'sweetalert2'; 
import ImageSlider from "../components/reuseable/imageSlider";

const UpdateRequest = () => {
  const [vehicleupdtRequests, setVehicleUpdateRequests] = useState([]);
  const [updatedata, setUpdateData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [selectedVehId, setSelectedVehicleId] = useState(null); 
  const [selectedOldVehmap, setOldVehiclemap] = useState({}); 
  const [selectedVehmap, setSelectedVehiclemap] = useState({}); 
  const [updtGallery, setSelectedVehicleGallery] = useState([]);
  const [imagePopupOpen, setImagePopupOpen] = useState(false);
  const [popupImageSrc, setPopupImageSrc] = useState('');
  const [isUpdatePopupOpen, setUpdatePopupOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const openImagePopup = (src) => {
    setPopupImageSrc(src);
    setImagePopupOpen(true);
  };

  const closeImagePopup = () => {
    setImagePopupOpen(false);
  };

  // Fetch vehicle update requests from backend
  useEffect(() => {
    const fetchVehicleUpdateRequests = async () => {
      try {
        const accessToken = sessionStorage.getItem("accessToken"); // Fetch the token from sessionStorage
        const response = await fetch(`${BASE_URL}/api/getVehicleUpdates`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`, // Ensure your request is authenticated
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch vehicle update requests');
        }

        const data = await response.json();
        setVehicleUpdateRequests(data); // Set fetched data to the state
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicleUpdateRequests();
  }, []);


  const fetchUpdatedata = async (vehicle) => {
    setOldVehiclemap(vehicle);
    console.log('vehicle', vehicle);
    setLoading(true); 
    try {
      const accessToken = sessionStorage.getItem("accessToken"); // Fetch the token from sessionStorage
  
      const response = await fetch(`${BASE_URL}/api/getUpdateCarDetails/${vehicle.token}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch update details');
      }
  
      const data = await response.json();
      console.log('data',data);

      if (Object.keys(data).length === 0) {
        console.log('empty');
        setUpdateData((prevState) => {
          const updatedState = { ...prevState };
          delete updatedState[vehicle.Carid]; 
          return updatedState;
        });
      } else {
        console.log('empty2');
        setUpdateData((prevState) => ({
          ...prevState,
          [vehicle.Carid]: data 
        }));
      }

      setSelectedVehicleId(vehicle.Carid);

    } catch (error) {
      setError(error.message); // Set error if there's an issue
    } finally {
      setLoading(false); // Set loading to false after the fetch completes
    }
  };


  const fetchUpdateGalleryImage = async (token) => {
    console.log('token', token);
    setLoading(true); 
    try {
      const accessToken = sessionStorage.getItem("accessToken"); // Fetch the token from sessionStorage
  
      const response = await fetch(`${BASE_URL}/api/getGalleryPendingImg/${token}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json', // Set the Content-Type to JSON
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch update details');
      }
      
      const data = await response.json(); // Parse the JSON response
      setSelectedVehicleGallery(data);
    } catch (error) {
      setError(error.message); // Set error if there's an issue
    } finally {
      setLoading(false); // Set loading to false after the fetch completes
    }
  };


  
  const openUpdatepopup = () => {
    setUpdatePopupOpen(true);
  };

  const closeUpdatePopup = () => {
    setUpdatePopupOpen(false);
  };


  const openModal = (type, vehmap) => {
    console.log('type',type,'vehmap',vehmap);
    setActionType(type);
    setSelectedVehiclemap(vehmap);
    setModalOpen(true);
  };

  const handleConfirm = async () => {
    if (actionType === 'accept') {
      await handleAccept(selectedVehmap);
    } else if (actionType === 'reject') {
      await handleReject(selectedVehmap);
    }
    setModalOpen(false);
    setUpdatePopupOpen(false);
  };

  // Function to handle Accept action
  const handleAccept = async (updtDTl) => {
    setModalOpen(false);
    const accessToken = sessionStorage.getItem("accessToken"); 
    console.log('vehicleDetails',updtDTl);

    if (updtDTl != null) {
    try {
      const apiUrl = `${BASE_URL}/api/ADMIN_Approve_VehicleUpdate`;
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          upid: updtDTl.up_id.toString(),
          tokn: updtDTl.veh_tok.toString(),
          verify: '1', // Assuming '1' for accept
          vehid: updtDTl.veh_id.toString(),
          dealid: updtDTl.veh_del_id.toString(),
          rej_rsn: '', // Assuming you handle this differently or as needed
        }),
      });

      if (response.ok) {
        const message = await response.json();
        console.log(message);

        // Handle Gallery Update
        const galleryApiUrl = `${BASE_URL}/api/ADMIN_GalleryUpdate`;
        const galleryResponse = await fetch(galleryApiUrl, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            action: 'GalleryUpdate',
            cli_id: updtDTl.veh_del_id.toString(),
            tokn: updtDTl.veh_tok.toString(),
            verify: '1',
          }),
        });

        if (galleryResponse.ok) {
          console.log('Gallery updated successfully');
        }

        await Swal.fire({
          title: 'Vehicle update approved',
          // text: 'Invalid OTP',
          icon: 'success',
          timer: 2000,
              showConfirmButton: false,  // Hides the OK button
              customClass: {
                popup: 'custom-popup',    // Class for the alert popup
                title: 'custom-title',    // Class for the title
                content: 'custom-content' // Class for the content text
              }
        }).then(() => {
          window.location.reload();
        });

      } else {
        alert('Failed to approve vehicle update');
      }
    } catch (e) {
      console.error('Error during approval:', e);
    }
  } else{

    const galleryApiUrl = `${BASE_URL}/api/ADMIN_GalleryUpdate`;
    const galleryResponse = await fetch(galleryApiUrl, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        action: 'GalleryUpdate',
        cli_id: selectedOldVehmap.dealid.toString(),
        tokn: selectedOldVehmap.token.toString(),
        verify: '1',
      }),
    });

    if (galleryResponse.ok) {
      console.log('Gallery updated successfully');
    }

    await Swal.fire({
      title: 'Vehicle update approved',
      // text: 'Invalid OTP',
      icon: 'success',
      timer: 2000,
          showConfirmButton: false,  // Hides the OK button
          customClass: {
            popup: 'custom-popup',    // Class for the alert popup
            title: 'custom-title',    // Class for the title
            content: 'custom-content' // Class for the content text
          }
    }).then(() => {
      window.location.reload();
    });
  }
};

/////////////////////////////////////////////////////////////


  const handleReject = async (updtDTl) => {
    const accessToken = sessionStorage.getItem("accessToken");
    setModalOpen(false);
    if (updtDTl != null) { 
    try {
      const response = await fetch(`${BASE_URL}/api/ADMIN_Approve_VehicleUpdate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          upid: updtDTl.up_id.toString(),
          tokn: updtDTl.veh_tok.toString(),
          verify: '2', // Assuming '1' for accept
          vehid: updtDTl.veh_id.toString(),
          dealid: updtDTl.veh_del_id.toString(),
          rej_rsn: '', // Assuming you handle this differently or as needed
        }),
      });

      if (response.ok) {

        const message = await response.json();
        console.log(message);

        // Handle Gallery Update
        const galleryApiUrl = `${BASE_URL}/api/ADMIN_GalleryUpdate`;
        const galleryResponse = await fetch(galleryApiUrl, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            action: 'GalleryUpdate',
            cli_id: updtDTl.veh_del_id.toString(),
            tokn: updtDTl.veh_tok.toString(),
            verify: '2',
          }),
        });

        if (galleryResponse.ok) {
          console.log('Gallery updated successfully');
        }
    
        await Swal.fire({
          title: 'Vehicle request rejected!',
          // text: 'Invalid OTP',
          icon: 'success',
          timer: 2000,
              showConfirmButton: false,  // Hides the OK button
              customClass: {
                popup: 'custom-popup',    // Class for the alert popup
                title: 'custom-title',    // Class for the title
                content: 'custom-content' // Class for the content text
              }
        }).then(() => {
          window.location.reload();
        });
      } else {
        alert("Failed to reject vehicle request");
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while rejecting the vehicle request");
    }

  }else {
    const galleryApiUrl = `${BASE_URL}/api/ADMIN_GalleryUpdate`;
    const galleryResponse = await fetch(galleryApiUrl, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        action: 'GalleryUpdate',
        cli_id: selectedOldVehmap.dealid.toString(),
        tokn: selectedOldVehmap.token.toString(),
        verify: '2',
      }),
    });

    if (galleryResponse.ok) {
      console.log('Gallery updated Rejected');
    }

    await Swal.fire({
      title: 'Vehicle update rejected',
      // text: 'Invalid OTP',
      icon: 'success',
      timer: 2000,
          showConfirmButton: false,  // Hides the OK button
          customClass: {
            popup: 'custom-popup',    // Class for the alert popup
            title: 'custom-title',    // Class for the title
            content: 'custom-content' // Class for the content text
          }
    }).then(() => {
      window.location.reload();
    });
  }
  };



  const handleDeleteImage = async (imgid,filename) => {
    const accessToken = sessionStorage.getItem("accessToken");
  
    try {
      const response = await fetch(`${BASE_URL}/api/deleteGalImg`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          img_Id: imgid, // Pass img_Id in the request body
          filename: filename, // Pass the filename in the request body
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete image');
      }

      // console.log('Image deleted successfully');
      //  alert("Image deleted successfully");

      await Swal.fire({
        title: 'Image deleted successfully!',
        // text: 'Invalid OTP',
        icon: 'success',
        timer: 2000,
            showConfirmButton: false,  // Hides the OK button
            customClass: {
              popup: 'custom-popup',    // Class for the alert popup
              title: 'custom-title',    // Class for the title
              content: 'custom-content' // Class for the content text
            }
      });
       
      await fetchUpdateGalleryImage(selectedOldVehmap.token);
    } catch (error) {
      console.error('Error deleting type:', error);
    }
  };

  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [sliderImages, setSliderImages] = useState([]);
  const [startIndex, setStartIndex] = useState(0);

  const openSlider = (images, index) => {
    setSliderImages(images);
    setStartIndex(index);
    setIsSliderOpen(true);
  };

  const closeSlider = () => {
    setIsSliderOpen(false);
  };



  const indexOfLastDealer = currentPage * itemsPerPage;
  const indexOfFirstDealer = indexOfLastDealer - itemsPerPage;
  const currentDealersReq = vehicleupdtRequests.slice(indexOfFirstDealer, indexOfLastDealer);
  const totalPages = Math.ceil(vehicleupdtRequests.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (loading) return <center><div className="waitingspinner"></div></center>;
  // if (error) return <p>Error: {error}</p>; 

  return (
    <div className="layout">
      <Sidebar />
      <div className="main__layout">
        <TopNav />
        <div className="dashboard">
          <div className="dashboard__wrapper">
            <div className="vehicle-update">
              <h2 className="vehicle-update__heading">Vehicle Awaiting Updates</h2>
              <table className="vehicle-update__table">
                <thead className="vehicle-update__thead">
                  <tr className="vehicle-update__thead-row">
                    <th className="vehicle-update__thead-cell">Sl.No</th>
                    <th className="vehicle-update__thead-cell">Vehicle Photo</th>
                    <th className="vehicle-update__thead-cell">Vehicle Name</th>
                    <th className="vehicle-update__thead-cell">Driver Name</th>
                    <th className="vehicle-update__thead-cell">Driver Number</th>
                    <th className="vehicle-update__thead-cell">Driver Photo/ Licence Photo</th>
                    <th className="vehicle-update__thead-cell">Licence Number</th>
                    <th className="vehicle-update__thead-cell">Driver Experience</th>
                    <th className="vehicle-update__thead-cell">Per /km</th>
                    <th className="vehicle-update__thead-cell">Per /day</th>
                    <th className="vehicle-update__thead-cell">Actions</th>
                  </tr>
                </thead>
                <tbody className="vehicle-update__tbody">
  {currentDealersReq.map((vehicle , index) => (
    <tr key={vehicle.Carid} className="vehicle-update__tbody-row">
      <td className="vehicle-update__tbody-cell">{indexOfFirstDealer+index + 1}</td>
      <td className="vehicle-update__tbody-cell">
        <img
          src={`${BASE_URL}/${vehicle.car_photo}`}
          alt={`${vehicle.car_name} pic`}
          className="vehicle-update__image"
          onClick={() =>
            openSlider(
              [
                `${BASE_URL}/${vehicle.car_photo}`,
                `${BASE_URL}/${vehicle.driver_photo_path}`,
                `${BASE_URL}/${vehicle.license_path}`,
              ],
              0
            )
          }
        />
      </td>
      <td className="vehicle-update__tbody-cell">{vehicle.car_name}</td>
      <td className="vehicle-update__tbody-cell">{vehicle.driver_name}</td>
      <td className="vehicle-update__tbody-cell">{vehicle.driver_number}</td>
      <td className="vehicle-update__tbody-cell updateimgflex">
        <img
          src={`${BASE_URL}/${vehicle.driver_photo_path}`}
          alt={`DriverImage`}
          className="vehicle-update__proof-image"
          onClick={() =>
            openSlider(
              [
                `${BASE_URL}/${vehicle.car_photo}`,
                `${BASE_URL}/${vehicle.driver_photo_path}`,
                `${BASE_URL}/${vehicle.license_path}`,
              ],
              1
            )
          }
        />
        <img
          src={`${BASE_URL}/${vehicle.license_path}`}
          alt={`${vehicle.car_name} Document`}
          className="vehicle-update__proof-image"
          onClick={() =>
            openSlider(
              [
                `${BASE_URL}/${vehicle.car_photo}`,
                `${BASE_URL}/${vehicle.driver_photo_path}`,
                `${BASE_URL}/${vehicle.license_path}`,
              ],
              2
            )
          }
        />
      </td>
      <td className="vehicle-update__tbody-cell">{vehicle.licence_num}</td>
      <td className="vehicle-update__tbody-cell">{vehicle.experience} years</td>
      <td className="vehicle-update__tbody-cell">₹ {vehicle.price_per_km} /km</td>
      <td className="vehicle-update__tbody-cell">₹ {vehicle.one_day_rent} /day</td>
      <td className="vehicle-update__tbody-cell">
        <button
          className="vehicle-update__action-btn updateview-btn"
          onClick={async () => {
           await fetchUpdatedata(vehicle);
           await fetchUpdateGalleryImage(vehicle.token);
            openUpdatepopup();
          }}
        >
          View Update
        </button>
      </td>
    </tr>
  ))}
</tbody>

               
              </table>

              {vehicleupdtRequests.length > 0 ? (
                <p></p>
                  ) : (
                   <center className='vehno--data'><p>No Update Request found...!</p></center>
                  )}


  {/* Pagination Controls */}
  {vehicleupdtRequests.length > 10 && (  
  <div className="pagination">
    <button 
      onClick={() => handlePageChange(currentPage - 1)} 
      disabled={currentPage === 1}
      className="pagination__button"
    >
      &laquo; Previous
    </button>
    {Array.from({ length: totalPages }, (_, index) => (
      <button
        key={index + 1}
        onClick={() => handlePageChange(index + 1)}
        className={`pagination__button ${currentPage === index + 1 ? 'active' : ''}`}
      >
        {index + 1}
      </button>
    ))}
    <button 
      onClick={() => handlePageChange(currentPage + 1)} 
      disabled={currentPage === totalPages}
      className="pagination__button"
    >
      Next &raquo;
    </button>
  </div>
)}




{isUpdatePopupOpen && (
  <div className="update-popup">

    <div className='update-popup-body'>
    <div className="popup-header">
      <h3>Update Details for Vehicle ID: {selectedVehId}</h3>
    </div>

    <div className="popup-content">
      <h4>Vehicle Information:</h4>

      <table className="vehicle-update__table">
  <thead className="vehicle-update__thead">
    <tr className="vehicle-update__thead-row">
      <th className="vehicle-update__thead-cell">Vehicle Photo</th>
      <th className="vehicle-update__thead-cell">Driver Name</th>
      <th className="vehicle-update__thead-cell">Driver Number</th>
      <th className="vehicle-update__thead-cell">Driver Photo/Licence Photo</th>
      <th className="vehicle-update__thead-cell">Licence Number</th>
      <th className="vehicle-update__thead-cell">Driver Experience</th>
      <th className="vehicle-update__thead-cell">Per /km</th>
      <th className="vehicle-update__thead-cell">Per /day</th>
    </tr>
  </thead>
  <tbody className="vehicle-update__tbody">
    {updatedata[selectedVehId] && Object.keys(updatedata[selectedVehId]).length > 0 ? (
      <tr className="vehicle-update__tbody-row">
        <td className="vehicle-update__tbody-cell">
          <img
            src={updatedata[selectedVehId].up_veh_img !== "null" ? `${BASE_URL}/${updatedata[selectedVehId].up_veh_img}` : require('../assets/images/no_img.jpg')}
            alt={`${updatedata[selectedVehId].car_name} pic`}
            className="vehicle-update__image"
            onClick={() =>updatedata[selectedVehId].up_veh_img !== "null" && openImagePopup(`${BASE_URL}/${updatedata[selectedVehId].up_veh_img}`)}
          /> 
        </td>
        <td className="vehicle-update__tbody-cell">{updatedata[selectedVehId].driv_nm}</td>
        <td className="vehicle-update__tbody-cell">{updatedata[selectedVehId].driv_num}</td>
        <td className="vehicle-update__tbody-cell updateimgflex">
         
        <img
      src={updatedata[selectedVehId].driv_img !== "null"
        ? `${BASE_URL}/${updatedata[selectedVehId].driv_img}` 
        : require('../assets/images/no_img.jpg')}
      alt="Driver Image"
      className="vehicle-update__proof-image"
      onClick={() =>
        updatedata[selectedVehId].driv_img !== "null" 
        && openImagePopup(`${BASE_URL}/${updatedata[selectedVehId].driv_img}`)
      }
    />
    <img
      src={updatedata[selectedVehId].driv_licn !== "null"
        ? `${BASE_URL}/${updatedata[selectedVehId].driv_licn}` 
        : require('../assets/images/no_img.jpg')}
      alt={`${updatedata[selectedVehId].car_name} Document`}
      className="vehicle-update__proof-image"
      onClick={() =>
        updatedata[selectedVehId].driv_licn !== "null"
        && openImagePopup(`${BASE_URL}/${updatedata[selectedVehId].driv_licn}`)
      }
    />
        </td>
        <td className="vehicle-update__tbody-cell">{updatedata[selectedVehId].licn_num}</td>
        <td className="vehicle-update__tbody-cell">{updatedata[selectedVehId].driv_exp} years</td>
        <td className="vehicle-update__tbody-cell">₹ {updatedata[selectedVehId].cost_km} /km</td>
        <td className="vehicle-update__tbody-cell">₹ {updatedata[selectedVehId].cost_day} /day</td>
      </tr>
    ) : (
      <tr className="vehicle-update__tbody-row">
        <td colSpan="8" className="vehicle-update__tbody-cell">No Updates Available</td>
      </tr>
    )}
  </tbody>
</table>

{/* Debugging View (Optional) */}
{/* {updatedata[selectedVehId] && Object.keys(updatedata[selectedVehId]).length > 0 ? (
  <pre>{JSON.stringify(updatedata[selectedVehId], null, 2)}</pre>
) : (
  <p>No Updates Available</p>
)} */}


      <h4>Gallery:</h4>
      {updtGallery.length > 0 ? (
  <div className="upgallery">
    {updtGallery.map((image, index) => (
      <div className="upgallery-item" key={index}>
        <i 
          className="ri-close-fill upgaldelete-icon" 
          onClick={() => handleDeleteImage(image.id,image.car_photo)}
        ></i>
        <img
          src={`${BASE_URL}/${image.car_photo}`}
          alt={`Gallery Image ${index}`}
          className="upgallery-image"
          onClick={() => openImagePopup(`${BASE_URL}/${image.car_photo}`)}
        />
      </div>
    ))}
  </div>
) : (
  <p>No Gallery Images Available</p>
)}


      {/* Conditionally render buttons based on whether updatedata or updtGallery is not empty */}
      {(updatedata[selectedVehId] && Object.keys(updatedata[selectedVehId]).length > 0) || updtGallery.length > 0 ? (
          <div className="updatepopfoot">
            <button className="vehicle-update__action-btn updateaccept-btn" onClick={() => openModal('accept', updatedata[selectedVehId])}>Accept</button>
            <button className="vehicle-update__action-btn updatereject-btn" onClick={() => openModal('reject', updatedata[selectedVehId])}>Reject</button>
            <button className="close-popup-btn" onClick={closeUpdatePopup}>Close</button>
          </div>
        ) : (
          <>
            {closeUpdatePopup()} {/* Close the popup */}
            {setTimeout(() => window.location.reload(), 100)} {}
          </>
        )}
    </div>
    </div>

        {/* Render the modal for confirming actions */}
        <ConfirmationModal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={handleConfirm}
                actionType={actionType}
                message={`Are you sure you want to ${actionType} this Update?`} 
              />

  </div>
)}


{imagePopupOpen && (
  <ImagePopup 
    src={popupImageSrc} 
    alt="Vehicle" 
    onClose={closeImagePopup} 
  />
)}


 {/* ImageSlider Component */}
 <ImageSlider
        images={sliderImages}
        isOpen={isSliderOpen}
        onClose={closeSlider}
        startIndex={startIndex}
      />
            
            </div>
           
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default UpdateRequest;
