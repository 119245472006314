import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import "../assets/css/header.css";
import main_logo from "../assets/logo/logo.png";

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const sea_taxi = () => {
        navigate(""); // Make sure to provide the correct route here
    
        // Scroll to the top after navigation
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    };
    
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            window.scrollTo({
                top: element.offsetTop - 70, // Adjust offset for sticky header height
                behavior: 'smooth'
            });
        }
    };


    return (
        <>
            <header className="web-taxi-header">
                <div className="web-taxi-logo">
                    <img src={main_logo} alt="Logo" />
                </div>
                <nav className={menuOpen ? 'web-taxi-menu open' : 'web-taxi-menu'}>
                    <ul>
                        <li>
                            <NavLink
                                to="/Home"
                                className={({ isActive }) => (isActive ? 'active-link' : '')}
                                onClick={sea_taxi}
                            >
                                HOME
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/About"
                                className={({ isActive }) => (isActive ? 'active-link' : '')}
                                onClick={sea_taxi}
                            >
                                ABOUT
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/Contact"
                                className={({ isActive }) => (isActive ? 'active-link' : '')}
                                onClick={sea_taxi}
                            >
                                CONTACT
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                              to="/Home"
                              onClick={(e) => {
                                  // Prevent the default behavior to control the navigation
                                  e.preventDefault();
                                  navigate("/Home"); // Navigate to the Home page
                                  setTimeout(() => {
                                      scrollToSection('how-it-works'); // Scroll to the "How It Works" section after navigating
                                  }, 0); // Use setTimeout to ensure scrolling happens after navigation
                              }}
                            >
                                HOW IT WORK
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/Home"
                                onClick={(e) => {
                                    e.preventDefault(); // Prevent navigation and only scroll
                                    navigate("/Home"); // Navigate to the Home page
                                    setTimeout(() => {
                                        scrollToSection('why-choose-us'); // Scroll to "Why Choose Us" section after navigating
                                    }, 0);
                                }}
                            >   
                               WHY CHOOSE US
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                               to="/Home"
                               onClick={(e) => {
                                   e.preventDefault(); // Prevent navigation and only scroll
                                   navigate("/Home"); // Navigate to the Home page
                                   setTimeout(() => {
                                       scrollToSection('download-app'); // Scroll to "Download" section after navigating
                                   }, 0);
                               }}
                            >
                                DOWNLOAD APP
                            </NavLink>
                        </li>
                    </ul>
                </nav>
                <div className="web-taxi-menu-icon" onClick={toggleMenu}>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </div>
            </header>
        </>
    );
};

export default React.memo(Header);
