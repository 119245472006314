import React, { useState, useEffect } from 'react';
import Sidebar from "../components/Sidebar/Sidebarr";
import TopNav from "../components/TopNav/TopNav";
import { BASE_URL } from '../api/config';
import "../styles/vehiclerequest.css";
import ConfirmationModal from "../components/Model/ConfirmationModal"; // Import your modal component
import Swal from 'sweetalert2'; 
import ImageSlider from "../components/reuseable/imageSlider";

const VehicleRequest = () => {
  const [vehicleRequests, setVehicleRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [selectedVehId, setSelectedVehicleId] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [itemsPerPage, setItemsPerPage] = useState(10); // Number of items per page





  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [sliderImages, setSliderImages] = useState([]);
  const [startIndex, setStartIndex] = useState(0);

  const openSlider = (images, index) => {
    setSliderImages(images);
    setStartIndex(index);
    setIsSliderOpen(true);
  };

  const closeSlider = () => {
    setIsSliderOpen(false);
  };



  // Fetch dealer requests from backend
  useEffect(() => {
    const fetchVehicleRequests = async () => {
      try {
        const accessToken = sessionStorage.getItem("accessToken"); // Fetch the token from sessionStorage
        const response = await fetch(`${BASE_URL}/api/getRegDealersVehicle`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`, // Ensure your request is authenticated
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch dealer requests');
        }

        const data = await response.json();
        setVehicleRequests(data); // Set fetched data to the state
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicleRequests();
  }, []);


  const openModal = (type, VehId) => {
    setActionType(type);
    setSelectedVehicleId(VehId);
    setModalOpen(true);
  };

  const handleConfirm = async () => {
    if (actionType === 'accept') {
      await handleAccept(selectedVehId);
    } else if (actionType === 'reject') {
      await handleReject(selectedVehId);
    } else if (actionType === 'delete') {
      await handleDelete(selectedVehId);
    }
    setModalOpen(false);
  };


 // Function to handle Accept action
const handleAccept = async (vehicle) => {
  try {
    const vehicleId = vehicle.id;
    const accessToken = sessionStorage.getItem("accessToken");
    const response = await fetch(`${BASE_URL}/api/ADMIN_Approve_Vehicle`, {
      method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                id: vehicleId.toString(),
                tokn: vehicle.token.toString(),
                verify: '1',
                dealid: vehicleId.toString(),
            }),
    });

    if (response.ok) {

      Swal.fire({
        title: 'Vehicle request accepted!',
        // text: 'Invalid OTP',
        icon: 'success',
        timer: 2000,
            showConfirmButton: false,  // Hides the OK button
            customClass: {
              popup: 'custom-popup',    // Class for the alert popup
              title: 'custom-title',    // Class for the title
              content: 'custom-content' // Class for the content text
            }
      }).then(() => {
        setVehicleRequests(vehicleRequests.filter((vehicle) => vehicle.id !== vehicleId));
      });
      // Remove the accepted vehicle from the list
    } else {
      alert("Failed to accept vehicle request");
    }
  } catch (error) {
    console.error(error);
    alert("An error occurred while accepting the vehicle request");
  }
};

// Function to handle Reject action
const handleReject = async (vehicle) => {
  try {
    const vehicleId = vehicle.id;
    const accessToken = sessionStorage.getItem("accessToken");
    const response = await fetch(`${BASE_URL}/api/ADMIN_Approve_Vehicle`, {
      method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                id: vehicleId.toString(),
                tokn: vehicle.token.toString(),
                verify: '2',
                dealid: vehicleId.toString(),
            }),
    });

    if (response.ok) {

      Swal.fire({
        title: 'Vehicle request rejected!',
        icon: 'success',
        timer: 2000,
            showConfirmButton: false,  // Hides the OK button
            customClass: {
              popup: 'custom-popup',    // Class for the alert popup
              title: 'custom-title',    // Class for the title
              content: 'custom-content' // Class for the content text
            }
      }).then(() => {
        setVehicleRequests(vehicleRequests.filter((vehicle) => vehicle.id !== vehicleId));
      });
      // Remove the rejected vehicle from the list
    } else {
      alert("Failed to reject vehicle request");
    }
  } catch (error) {
    console.error(error);
    alert("An error occurred while rejecting the vehicle request");
  }
};

// Function to handle Delete action
const handleDelete = async (vehicle) => {
  try {
    console.log('vehicle', vehicle);
    const vehicleId = vehicle.id;
    const accessToken = sessionStorage.getItem("accessToken");

    const response = await fetch(`${BASE_URL}/api/deleteTaxi`, {
      method: 'DELETE',
      body: JSON.stringify({
        car_id: vehicle.id.toString(),
      }),
      headers: {
        'Content-Type': 'application/json', // Ensure content type is JSON
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      alert("Vehicle request deleted!");
      // Remove the deleted vehicle from the list
      setVehicleRequests(vehicleRequests.filter((vehicle) => vehicle.id !== vehicleId));
    } else {
      alert("Failed to delete vehicle request");
    }
  } catch (error) {
    console.error(error);
    alert("An error occurred while deleting the vehicle request");
  }
};


  // Pagination logic
  const indexOfLastDealer = currentPage * itemsPerPage;
  const indexOfFirstDealer = indexOfLastDealer - itemsPerPage;
  const currentVehicleReq = vehicleRequests.slice(indexOfFirstDealer, indexOfLastDealer);
  const totalPages = Math.ceil(vehicleRequests.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  if (loading) return <center><div className="waitingspinner"></div></center>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="layout">
      <Sidebar />
      <div className="main__layout">
        <TopNav />
        <div className="dashboard">
          <div className="dashboard__wrapper">
            <div className="vehicle-requests">
              <h2 className="vehicle-requests__heading">Vehicle Requests</h2>
              <table className="vehicle-requests__table">
                <thead className="vehicle-requests__thead">
                  <tr className="vehicle-requests__thead-row">
                    {/* <th className="vehicle-requests__thead-cell">ID</th> */}
                    <th className="vehicle-requests__thead-cell">Vehicle Photo</th>
                    <th className="vehicle-requests__thead-cell">Vehicle Name</th>
                    <th className="vehicle-requests__thead-cell">Vehicle Number</th>
                    <th className="vehicle-requests__thead-cell">Vehicle Locate</th>
                    <th className="vehicle-requests__thead-cell">Per /km</th>
                    <th className="vehicle-requests__thead-cell">Per /day</th>
                    <th className="vehicle-requests__thead-cell">Driver / licence photo</th>
                    <th className="vehicle-requests__thead-cell">Actions</th>
                  </tr>
                </thead>
                <tbody className="vehicle-requests__tbody">
                  {currentVehicleReq.map((vehicle,index) => (
                    <tr key={vehicle.id} className="vehicle-requests__tbody-row">
                      {/* <td className="vehicle-requests__tbody-cell">{vehicle.id}</td> */}
                      <td className="vehicle-requests__tbody-cell">
                        <img
                          src={`${BASE_URL}/${vehicle.car_photo}`}
                          alt={`${vehicle.car_name} pic`}
                          className="vehicle-requests__image"
                          onClick={() =>
                            openSlider(
                              [
                                `${BASE_URL}/${vehicle.car_photo}`,
                                `${BASE_URL}/${vehicle.driver_photo_path}`,
                                `${BASE_URL}/${vehicle.license_path}`,
                              ],
                              0
                            )
                          }
                        />
                      </td>
                      <td className="vehicle-requests__tbody-cell">{vehicle.car_name}</td>
                      <td className="vehicle-requests__tbody-cell">{vehicle.car_number}</td>
                      <td className="vehicle-requests__tbody-cell">{vehicle.veh_locate}</td>
                      <td className="vehicle-requests__tbody-cell">₹ {vehicle.price_per_km} /km</td>
                      <td className="vehicle-requests__tbody-cell">₹ {vehicle.one_day_rent} /day</td>
                      <td className="vehicle-requests__tbody-cell">
                      <img
                          src={`${BASE_URL}/${vehicle.driver_photo_path}`}
                          alt={`DriverImage`}
                          className="vehicle-requests__proof-image"
                          onClick={() =>
                            openSlider(
                              [
                                `${BASE_URL}/${vehicle.car_photo}`,
                                `${BASE_URL}/${vehicle.driver_photo_path}`,
                                `${BASE_URL}/${vehicle.license_path}`,
                              ],
                              1
                            )
                          }
                        />
                        <img
                          src={`${BASE_URL}/${vehicle.license_path}`}
                          alt={`${vehicle.car_name} Document`}
                          className="vehicle-requests__proof-image"
                          onClick={() =>
                            openSlider(
                              [
                                `${BASE_URL}/${vehicle.car_photo}`,
                                `${BASE_URL}/${vehicle.driver_photo_path}`,
                                `${BASE_URL}/${vehicle.license_path}`,
                              ],
                              2
                            )
                          }

                        />
                      </td>
                      <td className="vehicle-requests__tbody-cell">
                        <button className="vehicle-requests__action-btn accept-btn" onClick={() => openModal('accept', vehicle)}>Accept</button>
                        <button className="vehicle-requests__action-btn reject-btn" onClick={() => openModal('reject', vehicle)}>Reject</button>
                        <i className="ri-delete-bin-fill  dlrbinicon" onClick={() =>  openModal('delete', vehicle)}></i>

                        {/* <button className="vehicle-requests__action-btn delete-btn" onClick={() => openModal('delete', vehicle)}>Delete</button> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {vehicleRequests.length > 0 ? (
                <p></p>
                  ) : (
                   <center className='vehno--data'><p>No Vehicle Request found...!</p></center>
                  )}

         {/* Pagination Controls */}
         {vehicleRequests.length > 10 && (
          <div className="pagination">
            <button 
              onClick={() => handlePageChange(currentPage - 1)} 
              disabled={currentPage === 1}
              className="pagination__button"
            >
              &laquo; Previous
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`pagination__button ${currentPage === index + 1 ? 'active' : ''}`}
              >
                {index + 1}
              </button>
            ))}
            <button 
              onClick={() => handlePageChange(currentPage + 1)} 
              disabled={currentPage === totalPages}
              className="pagination__button"
            >
              Next &raquo;
            </button>
          </div>
)}

            </div>
          </div>
        </div>
      </div>

      <ConfirmationModal 
        isOpen={isModalOpen} 
        onClose={() => setModalOpen(false)} 
        onConfirm={handleConfirm} 
        message={`Are you sure you want to ${actionType} this Vehicle?`} 
      />

 {/* ImageSlider Component */}
 <ImageSlider
        images={sliderImages}
        isOpen={isSliderOpen}
        onClose={closeSlider}
        startIndex={startIndex}
      />

    </div>
  );
};

export default VehicleRequest;
