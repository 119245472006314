import React, { useState, useEffect } from "react";
import "../styles/dashboard.css";
import SingleCard from "../components/reuseable/SingleCard";
import RecommendCarCard from "../components/UI/RecommendCarCard";
import { BASE_URL } from '../api/config';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate();
  const [countsDtls, setCountsDtls] = useState({
    dealerCount: 0,
    clientCount: 0,
    dealReqCount: 0,
  });
  const [vehCount, setVehCount] = useState(0);
  const [updtCount, setUpdtCount] = useState(0);
  const [upcomeOrder, setUpcomingOrder] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserListCounts = async () => {
      const accessToken = sessionStorage.getItem("accessToken");
      try {
        const url = `${BASE_URL}/api/getAllUsersDtls`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        console.log("Fetched data:", data);

        const dealerCount = data.filter(
          (user) => user["whoislogin"] === "Dealer" && user["Ad_Verify"] === 1
        ).length;
        const clientCount = data.filter(
          (user) => user["whoislogin"] === "Guest" && (user["Ad_Verify"] === 0 || user["Ad_Verify"] === 1)
        ).length;
        const dealReqCount = data.filter(
          (user) => user["whoislogin"] === "Dealer" && user["Ad_Verify"] === 0
        ).length;

        console.log("Counts:", { dealerCount, clientCount, dealReqCount });

        setCountsDtls({ dealerCount, clientCount, dealReqCount });
      } catch (error) {
        console.error("Error fetching user list counts:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchVehRequestCounts = async () => {
      const accessToken = sessionStorage.getItem("accessToken");
      try {
        const url = `${BASE_URL}/api/getVehReqCount`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        console.log("Fetched vehicle request data:", data);
        const vehReq = data[0].count;
        console.log("Vehicle Request Count:", vehReq);

        setVehCount(vehReq);
      } catch (error) {
        console.error("Error fetching vehicle request counts:", error);
        setError(error);
      }
    };
    const fetchUPdateRequestCount = async () => {
      const accessToken = sessionStorage.getItem("accessToken");
      try {
        const url = `${BASE_URL}/api/getVehicleUpdatesCount`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        console.log("Fetched update request data:", data);
        const updtReq = data.count;
        console.log("Vehicle update Count:", updtReq);

        setUpdtCount(updtReq);
      } catch (error) {
        console.error("Error fetching vehicle request counts:", error);
        setError(error);
      }
    };
    
    const fetchUpcomingOrders= async () => {
      const accessToken = sessionStorage.getItem("accessToken");
      try {
        const url = `${BASE_URL}/api/getUpComingDetails`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const upcomedata = await response.json();
        console.log('upcomedata',upcomedata);
        setUpcomingOrder(upcomedata);
      } catch (error) {
        console.error("Error fetching vehicle request counts:", error);
        setError(error);
      }
    };

    fetchUserListCounts();
    fetchVehRequestCounts();
    fetchUPdateRequestCount();
    fetchUpcomingOrders();
  }, []);

  if (loading) return <center><div className="waitingspinner"></div></center>;
  if (error) return <p>Error loading data: {error.message}</p>;


  const handleCardClick = (cardType) => {
    console.log(`${cardType} card clicked`);

    switch (cardType) {
      case "Dealers":
        navigate('/layout/dealerdtls');
        break;
      case "Clients":
        navigate('/layout/clientdtls');
        break;
      case "Dealer Requests":
        navigate('/dealer-requests');
        break;
      case "Vehicle Requests":
        navigate('/vehicle-requests');
        break;
      case "Update Requests":
        navigate('/update-requests');
        break;
      default:
        break;
    }
  };


  const handleView = (bookorder) => {
    navigate('/layout/view_booking_details', { state: { bookorder } });
    console.log('next page view_booking_details',bookorder);
  };

  return (
    <div className="dashboardmain">
      <div className="dashboardmain__wrapper">
        <div className="dashboard__cards">
          <SingleCard
            item={{
               title: "Dealers", 
               totalNumber: countsDtls.dealerCount,
               icon: "ri-timer-flash-line",
               
              }}
              onClick={() => handleCardClick("Dealers")}
          />
          <SingleCard
            item={{ 
              title: "Clients", 
              totalNumber: countsDtls.clientCount,
              icon: "ri-user-line",
            }}
            onClick={() => handleCardClick("Clients")}
          />
          <SingleCard
            item={{
              title: "Dealer Requests",
              totalNumber: countsDtls.dealReqCount,
              icon: "ri-steering-2-line", 
            }}
            onClick={() => 
              
              handleCardClick("Dealer Requests")}
           
          />
          <SingleCard
            item={{ 
              title: "Vehicle Requests", 
              totalNumber: vehCount ,
              icon: "ri-police-car-line", 
            }}
            onClick={() => handleCardClick("Vehicle Requests")}
          />

          <SingleCard
            item={{ 
              title: "Vehicle Update Requests", 
              totalNumber: updtCount ,
             icon: "ri-edit-box-line"
            }}
            onClick={() => handleCardClick("Update Requests")}
          />
        </div>

        <h2 className="ongoing__title">Ongoing / Upcoming Order Details</h2>

        <div className="recommend__cars-wrapper">
          {upcomeOrder.map((item) => (
            <RecommendCarCard item={item} 
            onClick={() => handleView(item)}
            key={item.bookid} />
          ))}
        </div>
        {upcomeOrder.length > 0 ? (
                <p></p>
                  ) : (
                   <center className='vehno--data'><p>No Bookings found...!</p></center>
                  )}
      </div>
    </div>
  );
};

export default Dashboard;
